<template>
  <div>
    <b-tabs v-model="tabIndex" pills>
      <b-tab
        v-for="(item, index) in buttonList"
        :key="index"
        :title="item.name"
        :active="tabIndex === index"
      />
    </b-tabs>

    <transition name="zoom-fade" mode="out-in">
      <component :is="currentComponent" />
    </transition>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BCardText,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';
import storeModule from '@/store/services/eCommerce/mlm';

import PurchaseBonus from './components/PurchaseBonus.vue';
import RetailBonus from './components/RetailBonus.vue';
import InviteBonus from './components/InviteBonus.vue';
import TeamBonus from './components/TeamBonus.vue';
import Cycle from './components/Cycle.vue';
import LeaderBonus from './components/LeaderBonus.vue';
import Settings from './components/Settings.vue';

const STORE_MODULE_NAME = 'mlm';

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BImg,
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    PurchaseBonus,
    RetailBonus,
    InviteBonus,
    TeamBonus,
    Cycle,
    LeaderBonus,
    Settings,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line radix
      tabIndex: parseInt(localStorage.getItem('tabIndex')) || 0,
    };
  },
  computed: {
    buttonList() {
      return [
        { name: this.$t('Purchase bonus'), component: 'PurchaseBonus' },
        // { name: this.$t('Retail bonus'), component: 'RetailBonus' },
        { name: this.$t('Invite bonus'), component: 'InviteBonus' },
        { name: this.$t('Team bonus'), component: 'TeamBonus' },
        { name: this.$t('Cycle 4 week'), component: 'Cycle' },
        { name: this.$t('Leader bonus'), component: 'LeaderBonus' },
        { name: this.$t('Settings'), component: 'Settings' },
      ];
    },
    currentComponent() {
      return this.buttonList[this.tabIndex]?.component || 'PurchaseBonus';
    },
  },
  watch: {
    tabIndex(newVal) {
      localStorage.setItem('tabIndex', newVal);
    },
  },
  created() {
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule);
    }
    this.fetchData();
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  },
  methods: {
    fetchData() {
      this.show = true;
      store
        .dispatch(`${STORE_MODULE_NAME}/get`)
        .then(() => {
          this.show = false;
        })
        .catch(error => {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response?.data?.message || 'An error occurred'),
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin: 0 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>

